<template lang="pug">
  ul.car-classes-list
    li.sticky-col.editable.d-flex
      .item-name
        span
        |  {{ $t('shops_sources_car_matching.car_class_name') }}
      input.editable-field.form-control.car-class-input(
        :class="{ invalid: isInvalid }"
        type="text"
        :disabled="disabled"
        :value="itemName"
        @input="setItemName"
      )
    li.d-flex(
      v-if="isItemOpened"
      v-for="ota in otaList"
      :key="ota.id"
      :class="ota.rawName"
    )
      .item-name
        span {{ ota.name }}
      .immutable(
        v-if="isSourceItemSelectionDisabled(ota.id)"
        :class="carClassStyle(ota.id)"
        :title="ota.name"
      )
        span {{ currentSourcesItemsByOtaId[ota.id].name }}
      AppDropdown(
        v-else
        lazy-scroll
        z-index="103"
        size="small"
        close-on-select
        searchable
        allow-empty
        title-key="name"
        value-key="id"
        :items="dropDownItems(ota.id, item.dp)"
        :value="currentSourcesItemsByOtaId[ota.id]"
        :placeholder="$t('car_class_matching.select_car_class')"
        :is-item-disabled="denySelectSourcesItem"
        :class="carClassStyle(ota.id)"
        @select="setSourcesItem"
        @remove="removeSourcesItem"
      )
        template(
          v-if="sourcesItemsLoading"
          v-slot:selectIcon
        )
          FaIcon.app-select-icon(
            icon="spinner"
            spin
          )
</template>

<script>
  // mixins
  import withMatchingPageMethods from "@/mixins/matching_page/withMatchingItemMethods"
  import { isUndefined } from "lodash-es"

  export default {
    props: {
      isItemOpened: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },

    components: {
      AppToggle: () => import("@/components/elements/AppToggle"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other"),
      AppCheckbox: () => import("@/components/elements/AppCheckbox")
    },

    methods: {
      carClassStyle(ota_id) {
        const currentSourcesCarClass = this.currentSourcesItemsByOtaId[ota_id]

        if (!currentSourcesCarClass) return null
        if (!isUndefined(currentSourcesCarClass.active) && !currentSourcesCarClass.active) return "crossout"

        return null
      }
    },

    mixins: [withMatchingPageMethods("sources_car_classes")]
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/matchings.sass"

  .car-classes-list
    padding: 0
    padding-top: 6px
    margin: 0
    height: 100%
    .immutable
      padding: 10px !important
      width: 100%
  .matchings-item
    height: 50px

    td
      +matchings-item-td

      &.inbound, &.dp
        padding-left: 0

        .app-checkbox
          justify-content: center

    +matchings-item-edit

    .editable
      min-width: 270px
  .car-class
    cursor: pointer
    justify-content: space-between

  .crossout ::v-deep span
    text-decoration: line-through
    color: red
    text-decoration-color: red

  .car-class-input
    margin: 5px 10px 0 10px

  .item-name
    padding: 10px
    width: 30%
</style>
