import { render, staticRenderFns } from "./CarClassMatchingItem.vue?vue&type=template&id=66388904&scoped=true&lang=pug&"
import script from "./CarClassMatchingItem.vue?vue&type=script&lang=js&"
export * from "./CarClassMatchingItem.vue?vue&type=script&lang=js&"
import style0 from "./CarClassMatchingItem.vue?vue&type=style&index=0&id=66388904&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66388904",
  null
  
)

export default component.exports